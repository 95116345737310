import BrandLogo from './logo-brand.png';
import DeveloperLogo from './logo-dev.png';

export { BrandLogo, DeveloperLogo };

export * from './DefaultAvatar';
export * from './NotFoundIllustration';
export * from './StarIcon';
export * from './XdaLogo';

export * from './database';
export * from './frameworks';
export * from './languages';
export * from './platforms';
