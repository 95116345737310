import './styles.scss';

import React from 'react';

import { StarIcon } from '#/images';

interface IProjectCardSkeletonProps {
    starred: boolean;
}

export const ProjectCardSkeleton: React.FC<IProjectCardSkeletonProps> = ({ starred }: IProjectCardSkeletonProps) => {
    return (
        <>
            <div className='project-card skeleton'>
                {starred ? (
                    <div className='badge'>
                        <i className='left'></i>
                        <i className='right'></i>
                        <StarIcon />
                    </div>
                ) : null}
                <div className='header'>
                    <span className='name'>&nbsp;</span>
                    <div className='people-list minimal skeleton'>
                        <div className='person'></div>
                    </div>
                </div>
                <div className='content'>
                    <div className='thumbnail-container'>
                        <div className='img-wrapper'></div>
                    </div>
                    <div className='description'></div>
                    <div className='icon-list minimal skeleton'>
                        <span className='caption'>&nbsp;</span>
                        <span>:</span>
                        <div className='icon-list-item'></div>
                        <div className='icon-list-item'></div>
                        <div className='icon-list-item'></div>
                        <div className='icon-list-item'></div>
                    </div>
                    <div className='icon-list minimal skeleton'>
                        <span className='caption'>&nbsp;</span>
                        <span>:</span>
                        <div className='icon-list-item'></div>
                        <div className='icon-list-item'></div>
                        <div className='icon-list-item'></div>
                    </div>
                </div>
                <div className='links-list minimal skeleton'>
                    <div className='left'>
                        <a className='link-item'></a>
                    </div>
                    <div className='right'>
                        <a className='link-item'></a>
                    </div>
                </div>
            </div>
        </>
    );
};
