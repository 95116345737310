import './styles.scss';

import React, { CSSProperties, ReactNode } from 'react';

import { Variant } from '#/types';

import { Person } from './Person';

export interface IPeopleListProps {
    people?: string[];
    variant: Variant;
}

export interface IPeopleListCSS extends CSSProperties {
    '--total-count': number;
}

export const PeopleList: React.FC<IPeopleListProps> = ({ people, variant }: IPeopleListProps) =>
    people && people.length > 0 ? (
        <div className={`people-list ${variant}`} style={{ '--total-count': people.length } as IPeopleListCSS} onClick={(e) => e.stopPropagation()}>
            {people.map<ReactNode>((userName, index) => (
                <Person key={`person-${index}`} index={index} userName={userName} />
            ))}
        </div>
    ) : null;
