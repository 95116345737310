import React, { PropsWithChildren, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID!, { gtagOptions: { send_page_view: false } });

export const AnalyticsPageViewProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: document.title,
        });
    }, [location]);

    return <>{children}</>;
};
