import './styles.scss';

import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { ProgressOverlay } from '#/components';
import { CaptchaProtectedRoute } from '#/utils';

const Home = React.lazy(() => import('./Home'));
const Contact = React.lazy(() => import('./Contact'));
const Projects = React.lazy(() => import('./Projects'));
const Resume = React.lazy(() => import('./Resume').catch(() => import('./NotFound')));
const NotFound = React.lazy(() => import('./NotFound'));

export const Pages: React.FC = () => (
    <React.Suspense fallback={<ProgressOverlay />}>
        <Routes>
            <Route path='/home' element={<Navigate to='/' />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/projects' element={<Projects />} />

            <Route element={<CaptchaProtectedRoute />}>
                <Route path='/resume' element={<Resume />} />
            </Route>

            <Route path='/' element={<Home />} />
            <Route element={<NotFound />} />
        </Routes>
    </React.Suspense>
);
