import React from 'react';

export const XdaLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' viewBox='184 184 32 32' {...props}>
        <path
            fill='#FFF'
            d='m198.56 183.938-.75.094c-9.443 1.152-15.787 10.455-13.47 19.752 2.655 10.649 15.217 15.516 24.333 9.425 10.446-6.979 9.148-22.838-2.284-27.908-2.332-1.034-5.805-1.639-7.829-1.363m3.889 11.524c.103.103.106 7.008.004 7.491-.253 1.197-1 1.535-3.238 1.467-1.53-.047-1.895-.199-2.278-.947l-.188-.367-.019-1.897c-.035-3.479.169-3.699 3.434-3.699h1.672v-.986c0-1.076.018-1.136.344-1.136.106-.001.227.032.269.074m-10.914 3.496c.602.78 1.109 1.417 1.126 1.417.017-.001.511-.639 1.097-1.418l1.066-1.417.301-.02c.292-.019.403.04.406.21 0 .036-.546.776-1.215 1.645l-1.215 1.581.294.383c.161.21.702.903 1.202 1.538 1.084 1.376 1.02 1.277.936 1.435-.079.146-.475.176-.637.048-.054-.043-.567-.683-1.14-1.42-.573-.738-1.065-1.343-1.095-1.343s-.509.576-1.063 1.28c-1.304 1.653-1.213 1.56-1.536 1.56-.375 0-.471-.174-.251-.455.085-.11.659-.832 1.276-1.604l1.122-1.404-.538-.697-1.237-1.599c-.816-1.054-.853-1.188-.31-1.157l.317.019 1.094 1.418m17.133-1.354c1.185.309 1.347.718 1.347 3.401 0 3.243-.221 3.487-3.08 3.417-1.58-.039-1.94-.174-2.381-.886l-.188-.306v-1.159c0-1.278.023-1.374.429-1.806.439-.467.477-.475 2.599-.509l1.903-.031.02-.333c.026-.461-.162-.842-.51-1.027-.248-.133-.341-.14-2.35-.169l-2.091-.031-.02-.239c-.035-.427-.087-.417 2.043-.417 1.511 0 1.99.02 2.279.095m-10.355.683c-.81.219-.922.577-.896 2.866.028 2.491.058 2.532 1.837 2.587 1.443.044 2.042-.114 2.381-.624l.171-.26v-4.627l-1.592-.012c-1.167-.01-1.674.009-1.901.07m7.501 2.182c-.661.178-.826.497-.824 1.597.002 1.514.284 1.71 2.419 1.674 1.872-.031 1.879-.038 1.906-1.99l.019-1.369-1.61.004c-1.104.003-1.704.029-1.91.084'
        />
    </svg>
);
