import { observer } from 'mobx-react';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Outlet } from 'react-router-dom';

import { useStore } from '#/store';

const isDevelopment = process.env.NODE_ENV !== 'production';

export const CaptchaProtectedRoute: React.FC = observer(() => {
    const { auth } = useStore();

    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<string | null>(null);

    if (auth.reCaptchaToken || isDevelopment) {
        return <Outlet />;
    }

    return (
        <div className='page protected recaptcha'>
            <div className={`captcha-container ${loading ? 'loading' : ''}`}>
                <div className='message'>Plese complete the captcha to continue</div>
                <ReCAPTCHA
                    sitekey={process.env.RECAPTCHA_SITE_KEY!}
                    asyncScriptOnLoad={() => setLoading(false)}
                    onChange={(token) => {
                        auth.setReCaptchaToken(token);
                        setError(null);
                    }}
                    onErrored={() => {
                        setError('Error loading captcha. Please try again later.');
                    }}
                    onExpired={() => {
                        setError('Captcha expired. Please try again.');
                    }}
                />

                {error && <div className='error'>{error}</div>}

                {loading && (
                    <div className='loading-overlay'>
                        <div className='loader'>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});
