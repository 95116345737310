import './styles.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import Markdown from 'markdown-to-jsx';

import { Project, Variant } from '#/types';
import { DatabaseIconMap, FrameworkIconMap, LanguageIconMap, PlatformIconMap, RoleIconMap } from '#/utils';

import { Carousel } from '../Carousel';
import { ContributionsList } from '../ContributionsList';
import { IconList } from '../IconList';
import { LinksList } from '../LinksList';
import { PeopleList } from '../PeopleList';

interface ProjectInfoModalProps {
    isOpen: boolean;
    project: Project;
    onClose: () => void;
}

export const ProjectInfoModal: React.FC<ProjectInfoModalProps> = ({ isOpen, project, onClose }: ProjectInfoModalProps) => {
    return isOpen
        ? ReactDOM.createPortal(
              <div className='project-info modal-wrapper' onClick={onClose}>
                  <div className='modal' onClick={(e) => e.stopPropagation()}>
                      <span className='close' onClick={onClose}>
                          +
                      </span>
                      <div className='content'>
                          <header>
                              <h2>{project.name}</h2>
                          </header>
                          <main>
                              <Carousel images={project.images} theme='light' renderArrows renderIndicator />
                              <div className='info-container'>
                                  <div className='info'>
                                      <h3 className='description'>Description</h3>
                                      <Markdown>{project.description || 'No description provided'}</Markdown>

                                      {project.contributions && project.contributions.length > 0 ? (
                                          <>
                                              <h3>Contributions</h3>
                                              <ContributionsList contributions={project.contributions} />
                                          </>
                                      ) : null}

                                      {project.languages && project.languages.length > 0 ? (
                                          <>
                                              <h3>Languages</h3>
                                              <IconList items={project.languages} iconMap={LanguageIconMap} variant={Variant.Full} />
                                          </>
                                      ) : null}

                                      {project.database && project.database.length > 0 ? (
                                          <>
                                              <h3>Databases</h3>
                                              <IconList items={project.database} iconMap={DatabaseIconMap} variant={Variant.Full} />
                                          </>
                                      ) : null}

                                      {project.frameworks && project.frameworks.length > 0 ? (
                                          <>
                                              <h3>Frameworks</h3>
                                              <IconList items={project.frameworks} iconMap={FrameworkIconMap} variant={Variant.Full} />
                                          </>
                                      ) : null}

                                      {project.platforms && project.platforms.length > 0 ? (
                                          <>
                                              <h3>Platforms</h3>
                                              <IconList items={project.platforms} iconMap={PlatformIconMap} variant={Variant.Full} />
                                          </>
                                      ) : null}

                                      {project.roles && project.roles.length > 0 ? (
                                          <>
                                              <h3>Roles</h3>
                                              <IconList items={project.roles} iconMap={RoleIconMap} variant={Variant.Full} />
                                          </>
                                      ) : null}

                                      {project.people && project.people.length > 0 ? (
                                          <>
                                              <h3 className='people'>People</h3>
                                              <PeopleList people={project.people} variant={Variant.Full} />
                                          </>
                                      ) : null}

                                      {project.year ? (
                                          <>
                                              <h3>Year</h3>
                                              <p>{project.year}</p>
                                          </>
                                      ) : null}

                                      {project.status ? (
                                          <>
                                              <h3>Status</h3>
                                              <p>{project.status.toUpperCase()}</p>
                                          </>
                                      ) : null}
                                  </div>
                                  <LinksList links={project.links} variant={Variant.Full} />
                              </div>
                          </main>
                      </div>
                  </div>
              </div>,
              document.getElementById('root') || document.body
          )
        : null;
};
