import React from 'react';

export const XAMLIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={128} height={128} {...props}>
        <g clipRule='evenodd'>
            <path
                fill='none'
                d='M-1.382 87.483V18.872h131.37v68.611H-1.382zm88.197-13.11c.162-.202.35-.39.483-.61.979-1.612 1.947-3.231 2.919-4.849.452-.742.913-1.479 1.354-2.229.981-1.667 1.951-3.341 2.926-5.012l4.277-7.197a.288.288 0 0 0 .115-.134l.092-.022-.066-.144c-.103-.208-.188-.426-.309-.621-4.114-6.589-8.23-13.177-12.35-19.763a2643.67 2643.67 0 0 0-6.26-9.976c-.164-.26-.432-.456-.651-.682l-4.646-.001-14.118-.197c-3.627-.008-7.262.118-10.879-.074-2.09-.111-3.417.394-4.336 2.383-.95 2.055-2.246 3.949-3.391 5.914-.467.723-.956 1.434-1.398 2.172-1.297 2.163-2.576 4.336-3.862 6.506l-3.343 5.487a22332.96 22332.96 0 0 0-3.024 5.035l-1.076 1.802c.112.276.188.575.343.824a3703.38 3703.38 0 0 0 7.216 11.525c1.229 1.954 2.476 3.896 3.714 5.843l3.513 5.624 3.328 5.288 1.335 2.187.133.118c1.481.009 2.961.017 4.442.024l6.046.006 7.271.011 9.273.004 5.056.021c.262-.221.596-.396.774-.671a81.424 81.424 0 0 0 1.933-3.156c1.067-1.807 2.114-3.623 3.166-5.436zm12.655 4.311c.314.732.471 1.318.79 1.795.858 1.279 1.775 2.52 2.69 3.76.612.831 1.991.848 2.604.018.417-.564.75-1.191 1.13-1.784l17.23-26.839c1.105-1.722 1.104-3.525.102-5.232-1.578-2.688-3.288-5.299-4.939-7.944-2.895-4.637-5.783-9.277-8.681-13.912-1.279-2.047-2.55-4.102-3.872-6.121-.79-1.208-2.082-1.192-2.843.015-.965 1.532-1.88 3.095-2.814 4.646-.689 1.145-.665 2.246.057 3.401 4.298 6.884 8.542 13.801 12.872 20.665.842 1.334.779 2.469.001 3.733a6741.84 6741.84 0 0 0-12.985 21.186c-.511.832-.9 1.742-1.342 2.613M3.472 52.98c.374.945.605 1.95 1.124 2.773 3.289 5.222 6.645 10.402 9.973 15.6 2.641 4.123 5.292 8.241 7.901 12.385.699 1.11 2.184 1.667 3.26.119a184.796 184.796 0 0 1 2.324-3.282c.817-1.122.91-2.267.186-3.451-4.514-7.382-9.019-14.769-13.571-22.128-.889-1.437-.855-2.68.045-4.104 4.28-6.777 8.497-13.594 12.745-20.392.728-1.165.784-2.304.048-3.49-.92-1.481-1.785-2.997-2.713-4.473-.889-1.413-2.041-1.396-2.992.005-.132.194-.265.388-.39.587-5.63 8.979-11.273 17.951-16.865 26.954-.514.826-.699 1.856-1.075 2.897z'
            />
            <path
                fill='#CECECE'
                fillRule='evenodd'
                d='M99.47 78.684c.442-.871.831-1.78 1.341-2.615 4.314-7.07 8.646-14.131 12.985-21.186.778-1.264.841-2.399-.001-3.733-4.33-6.864-8.574-13.781-12.872-20.665-.722-1.155-.746-2.256-.057-3.401.935-1.551 1.85-3.114 2.814-4.646.761-1.208 2.053-1.224 2.843-.015 1.322 2.02 2.593 4.074 3.872 6.121 2.897 4.635 5.786 9.275 8.681 13.912 1.651 2.646 3.361 5.256 4.939 7.944 1.003 1.707 1.004 3.51-.102 5.232l-17.23 26.839c-.38.593-.713 1.22-1.13 1.784-.612.83-1.991.813-2.604-.018-.915-1.24-1.832-2.48-2.69-3.76-.319-.475-.475-1.061-.789-1.793zM3.472 52.98c.375-1.041.561-2.072 1.074-2.898 5.592-9.003 11.235-17.974 16.865-26.954.125-.199.258-.393.39-.587.951-1.401 2.103-1.418 2.992-.005.928 1.476 1.793 2.992 2.713 4.473.736 1.186.68 2.325-.048 3.49-4.248 6.797-8.465 13.614-12.745 20.392-.9 1.425-.934 2.668-.045 4.104 4.552 7.359 9.057 14.746 13.571 22.128.724 1.185.632 2.329-.186 3.451a183.3 183.3 0 0 0-2.324 3.282c-1.076 1.548-2.561.991-3.26-.119-2.609-4.144-5.26-8.262-7.901-12.385-3.328-5.197-6.684-10.377-9.973-15.6-.518-.822-.749-1.827-1.123-2.772z'
            />
            <path
                fill='#467CBF'
                fillRule='evenodd'
                d='M79.346 23.134c.22.226.487.421.651.682a2643.67 2643.67 0 0 1 6.26 9.976c4.119 6.586 8.235 13.174 12.35 19.763.121.195.206.414.309.621a.38.38 0 0 0-.025.167.294.294 0 0 1-.115.134l-5.917-.09-12.902-.049c-.324-1.837-1.614-2.989-3.071-3.839-2.768-1.616-5.849-2.299-9.027-2.577-1.02-.089-2.031-.261-3.046-.396.078-.18.142-.368.235-.54.659-1.226 1.324-2.449 1.988-3.673.17-.159.389-.29.504-.482 1.385-2.331 2.755-4.671 4.129-7.009 1.255-2.043 2.522-4.078 3.756-6.133.411-.685.734-1.421 1.098-2.134.938-1.473 1.88-2.947 2.823-4.421z'
            />
            <path
                fill='#91BFE7'
                fillRule='evenodd'
                d='m92.857 54.386 5.917.09-4.277 7.197c-2.186.306-4.366.593-6.277 1.861-2.173 1.442-4.43 2.762-6.565 4.257-2.107 1.475-4.108 3.1-6.154 4.66-.561.428-.691.842-.231 1.51.731 1.061 1.474 2.121 1.259 3.585-.295 2.011-.433 4.045-.638 6.068l-9.273-.004c.242-6.507 1.306-12.907 2.281-19.044.756.041 1.392.277 1.776.063 2.276-1.266 4.456-2.708 6.74-3.958 4.352-2.382 8.595-5.056 13.733-5.521.587-.052 1.14-.499 1.709-.764z'
            />
            <path
                fill='#5D96D0'
                fillRule='evenodd'
                d='M71.666 35.822c-1.374 2.338-2.744 4.678-4.129 7.009-.115.192-.334.323-.504.481-.183-.084-.44-.292-.534-.234-1.272.79-2.564 1.562-3.756 2.462-.407.308-.889 1.103-.751 1.429.364.864 1.047 1.593 1.603 2.375l1.421 2.816-2.234 1.426-.599-.62c-.258-.264-.509-.749-.774-.757-2.017-.064-4.037-.035-6.057-.035v.257h3.26c-2.342 8.414-4.706 16.647-6.468 25.029l.243.099 1.379-1.826.22.106-.688 7.754-4.442-.023a.258.258 0 0 0-.133-.119l-1.335-2.187c.219-.392.532-.759.639-1.178.304-1.191.498-2.411.783-3.607.935-3.926 1.848-7.857 2.853-11.765.987-3.841 2.063-7.659 3.134-11.478.176-.625.087-.88-.593-.87-1.242.02-2.484.006-3.726.006l-5.044-.002-16.161-.21 1.076-1.802c1.143-.217 2.303-.374 3.426-.667 1.251-.326 2.458-.825 3.707-1.162 2.24-.603 4.498-1.141 6.749-1.707.097-.024.191-.059.29-.075 1.261-.208 2.519-.43 3.783-.613.745-.108 1.498-.155 2.248-.226.464-.043.934-.051 1.394-.124 1.663-.261 3.236-.892 5.028-.549 1.053.201 2.236-.279 3.417-.461-.792-1.71-.827-1.764.381-2.552 1.452-.946 2.974-1.784 4.459-2.68 1.4-.846 2.781-1.725 4.191-2.555.724-.424 1.494-.778 2.244-1.165z'
            />
            <path
                fill='#80B4E1'
                fillRule='evenodd'
                d='m62.183 52.968.599.62 4.032 6.676c3.674-1.81 7.135-3.506 10.583-5.23.227-.113.354-.43.526-.651l2.032-.045 12.902.049c-.569.265-1.122.712-1.71.765-5.139.464-9.382 3.139-13.733 5.521-2.284 1.25-4.464 2.691-6.74 3.958-.385.214-1.021-.021-1.776-.063-.976 6.137-2.039 12.538-2.281 19.044l-7.271-.01c.339-2.81.619-5.627 1.03-8.425.532-3.621 1.168-7.226 1.76-10.838l.483-2.947-.287-.078-3.708 5.938-.256-.076c.034-.375.028-.759.112-1.121.172-.747.386-1.484.596-2.222 1.032-3.622 2.07-7.243 3.107-10.865z'
            />
            <path
                fill='#659FD5'
                fillRule='evenodd'
                d='M71.666 35.822c-.75.387-1.521.741-2.246 1.168-1.41.83-2.791 1.709-4.191 2.555-1.485.896-3.007 1.734-4.459 2.68-1.208.788-1.173.842-.381 2.552-1.18.182-2.363.662-3.417.461-1.792-.343-3.366.288-5.028.549-.46.072-.929.08-1.394.124-.75.07-1.503.118-2.248.226-1.264.183-2.522.405-3.783.613-.098.016-.193.05-.29.075-2.25.565-4.508 1.104-6.749 1.707-1.249.336-2.455.835-3.707 1.162-1.124.293-2.283.45-3.426.667l3.024-5.035c.426-.074.87-.093 1.276-.229 2.776-.929 5.544-1.883 8.315-2.826.221-.075.451-.124.676-.186 2.117-.588 4.23-1.189 6.352-1.757.739-.198 1.509-.28 2.249-.473 1.543-.403 3.164-.654 4.591-1.316 2.15-.996 4.175-2.267 6.229-3.463 3.585-2.087 7.14-4.225 10.732-6.298.857-.495 1.814-.818 2.726-1.22-.363.713-.687 1.449-1.098 2.134-1.231 2.052-2.498 4.087-3.753 6.13z'
            />
            <path
                fill='#80B4E1'
                fillRule='evenodd'
                d='M76.52 27.555c-.911.402-1.868.725-2.726 1.22-3.593 2.073-7.147 4.211-10.732 6.298-2.054 1.196-4.079 2.467-6.229 3.463-1.428.662-3.048.913-4.591 1.316-.74.193-1.51.275-2.249.473-2.122.568-4.235 1.169-6.352 1.757-.225.063-.455.111-.676.186-2.771.943-5.54 1.896-8.315 2.826-.406.136-.85.155-1.276.229l3.343-5.487c2.045-.707 4.087-1.42 6.135-2.12 1.199-.41 2.412-.781 3.609-1.196 2.049-.711 4.088-1.452 6.139-2.159.307-.106.708-.23.971-.12.959.406 1.696-.079 2.453-.496a6008.663 6008.663 0 0 0 17.762-9.796c.349-.193.611-.542.915-.817l4.646.001-2.827 4.422z'
            />
            <path
                fill='#91BFE7'
                fillRule='evenodd'
                d='M74.699 23.133c-.304.276-.566.624-.915.817a5786.668 5786.668 0 0 1-17.762 9.796c-.757.417-1.494.901-2.453.496-.262-.11-.664.014-.971.12-2.051.707-4.09 1.447-6.139 2.159-1.197.416-2.41.787-3.609 1.196-2.047.699-4.09 1.413-6.135 2.12 1.286-2.169 2.564-4.343 3.862-6.506.442-.738.931-1.449 1.398-2.172.486-.098 1.005-.119 1.453-.309 1.48-.625 2.917-1.356 4.406-1.958.874-.353 1.715-1.201 2.793-.419.095.069.377-.077.551-.166 2.745-1.4 5.498-2.788 8.219-4.233.465-.248.793-.752 1.185-1.138l14.117.197z'
            />
            <path
                fill='#659FD5'
                fillRule='evenodd'
                d='M62.183 52.968c-1.037 3.622-2.075 7.243-3.109 10.866-.21.738-.424 1.475-.596 2.222-.084.362-.078.746-.112 1.121l.256.076 3.708-5.938.287.078-.483 2.947c-.591 3.612-1.228 7.217-1.76 10.838-.411 2.798-.691 5.615-1.03 8.425l-6.046-.007.688-7.754-.22-.106-1.379 1.826-.243-.099c1.762-8.382 4.125-16.615 6.468-25.029h-3.26v-.257c2.02 0 4.04-.029 6.057.035.265.007.516.492.774.756z'
            />
            <path
                fill='#467CBF'
                fillRule='evenodd'
                d='M29.273 52.161c5.387.07 10.773.141 16.161.21-.457 1.665-.949 3.321-1.365 4.996-.976 3.937-1.905 7.886-2.885 11.822-.104.415-.42.777-.638 1.164-1.238-1.947-2.485-3.89-3.714-5.843a3703.38 3703.38 0 0 1-7.216-11.525c-.155-.249-.231-.548-.343-.824z'
            />
            <path
                fill='#B2D5F0'
                fillRule='evenodd'
                d='M75.89 83.615c.205-2.023.343-4.058.638-6.068.215-1.464-.527-2.524-1.259-3.585-.46-.668-.329-1.082.231-1.51 2.046-1.561 4.047-3.186 6.154-4.66 2.136-1.495 4.393-2.815 6.565-4.257 1.911-1.268 4.092-1.556 6.277-1.861-.975 1.671-1.944 3.345-2.926 5.012-.44.749-.901 1.486-1.354 2.229-.369.12-.796.16-1.098.375-1.387.988-2.771 1.984-4.096 3.054a147.361 147.361 0 0 0-5.738 4.848c-.445.398-.937 1.181-.83 1.657.161.719.83 1.323 1.283 1.977.157.316.333.625.469.95.258.615.492 1.24.736 1.861l-5.052-.022z'
            />
            <path
                fill='#528CC9'
                fillRule='evenodd'
                d='M50.478 52.374c1.242 0 2.484.013 3.726-.006.68-.01.769.244.593.87-1.071 3.819-2.147 7.637-3.134 11.478-1.005 3.908-1.917 7.839-2.853 11.765-.285 1.196-.479 2.416-.783 3.607-.107.419-.42.786-.639 1.178l-3.329-5.288c.232-.227.605-.416.674-.684.468-1.833.883-3.68 1.296-5.526.507-2.268.923-4.559 1.508-6.805.924-3.545 1.955-7.061 2.941-10.589z'
            />
            <path
                fill='#4E87C6'
                fillRule='evenodd'
                d='M50.478 52.374c-.985 3.528-2.017 7.044-2.939 10.588-.585 2.247-1.001 4.538-1.508 6.805a189.26 189.26 0 0 1-1.296 5.526c-.069.268-.442.457-.674.684l-3.514-5.624c.218-.387.534-.749.638-1.164.98-3.936 1.909-7.885 2.885-11.822.416-1.675.908-3.331 1.365-4.996 1.68.002 3.361.002 5.043.003z'
            />
            <path
                fill='#B2D5F0'
                fillRule='evenodd'
                d='M60.582 22.936c-.391.386-.72.89-1.185 1.138-2.722 1.446-5.474 2.833-8.219 4.233-.173.089-.456.235-.551.166-1.078-.782-1.919.066-2.793.419-1.489.603-2.926 1.333-4.406 1.958-.447.189-.966.21-1.453.309 1.145-1.965 2.441-3.86 3.391-5.915.919-1.989 2.246-2.494 4.336-2.383 3.618.193 7.253.067 10.88.075z'
            />
            <path
                fill='#BEDBF3'
                fillRule='evenodd'
                d='M79.739 80.824c-.453-.653-1.122-1.258-1.283-1.977-.106-.477.385-1.259.83-1.657 1.864-1.67 3.791-3.274 5.738-4.848 1.324-1.069 2.709-2.065 4.096-3.054.302-.215.729-.255 1.098-.375-.972 1.617-1.94 3.236-2.919 4.849-.134.221-.321.408-.483.61-.349.18-.75.3-1.036.55-1.428 1.244-2.851 2.496-4.221 3.803-.667.636-1.217 1.396-1.82 2.099'
            />
            <path
                fill='#C8E1F5'
                fillRule='evenodd'
                d='M79.739 80.824c.603-.703 1.152-1.463 1.819-2.099 1.37-1.307 2.793-2.559 4.221-3.803.286-.25.688-.37 1.036-.55-1.053 1.813-2.1 3.629-3.163 5.436a81.424 81.424 0 0 1-1.933 3.156c-.179.275-.513.45-.774.671-.245-.621-.479-1.246-.737-1.861-.136-.325-.312-.633-.469-.95z'
            />
            <path fill='#5D96D0' fillRule='evenodd' d='M98.89 54.342a.395.395 0 0 1 .025-.167l.066.144-.091.023z' />
            <path fill='#659FD5' fillRule='evenodd' d='M48.723 83.452a.258.258 0 0 1 .133.119z' />
            <path
                fill='#4E87C6'
                fillRule='evenodd'
                d='m79.955 54.337-2.032.045c-.349-.064-.697-.181-1.047-.183-2.713-.016-5.431-.101-8.138.025-1.593.074-2.798-.214-3.424-1.807-.043-.108-.196-.172-.299-.256l-1.421-2.816 1.216-1.819c1.015.134 2.027.307 3.046.396 3.179.277 6.26.961 9.027 2.577 1.458.85 2.748 2.001 3.072 3.838z'
            />
            <path
                fill='#528CC9'
                fillRule='evenodd'
                d='m64.81 47.526-1.216 1.819c-.556-.783-1.239-1.512-1.603-2.375-.138-.326.344-1.122.751-1.429 1.192-.901 2.484-1.673 3.756-2.462.094-.058.352.15.534.234-.664 1.225-1.329 2.448-1.988 3.674-.092.172-.156.359-.234.539'
            />
            <path
                fill='#659FD5'
                fillRule='evenodd'
                d='M65.016 52.162c.103.084.256.148.299.256.626 1.593 1.831 1.881 3.424 1.807 2.707-.126 5.425-.041 8.138-.025.35.002.698.119 1.047.183-.173.222-.3.538-.526.651-3.448 1.724-6.909 3.42-10.583 5.23l-4.032-6.676 2.233-1.426z'
            />
        </g>
        <path d='M46.563 114.682H44.16l-3.867-6.434a5.83 5.83 0 0 1-.387-.832h-.047c-.078.18-.211.457-.398.832l-3.984 6.434h-2.414l5.625-8.449-5.18-8.355h2.414l3.434 5.906c.227.391.426.781.598 1.172H40c.25-.516.469-.922.656-1.219l3.574-5.859h2.262l-5.285 8.332 5.356 8.472zm15.562 0h-2.18l-1.781-4.711h-7.125l-1.676 4.711h-2.191l6.445-16.805h2.039l6.469 16.805zm-4.605-6.481-2.637-7.16c-.086-.234-.172-.609-.258-1.125h-.047c-.078.477-.168.852-.27 1.125l-2.613 7.16h5.825m24.21 6.481h-1.957v-11.273c0-.891.055-1.98.164-3.27h-.047c-.188.758-.355 1.301-.504 1.629l-5.742 12.914h-.961l-5.73-12.82c-.164-.375-.332-.949-.504-1.723h-.047c.063.672.094 1.77.094 3.293v11.25h-1.898V97.877H67.2l5.156 11.719c.398.898.656 1.57.773 2.016h.07c.336-.922.605-1.609.809-2.063l5.262-11.672h2.461v16.805zm13.137 0h-8.719V97.877h1.969V112.9h6.75v1.782' />
    </svg>
);
