import Cookies from 'js-cookie';
import { makeAutoObservable } from 'mobx';

export class Auth {
    public reCaptchaToken: string | null;

    public constructor() {
        this.reCaptchaToken = null;

        makeAutoObservable(this);
    }

    public setReCaptchaToken(token: string | null) {
        this.reCaptchaToken = token;

        if (token === null) {
            Cookies.remove('reCaptchaToken');
        } else {
            Cookies.set('reCaptchaToken', token);
        }
    }
}
