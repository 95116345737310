import './styles.scss';

import React, { ReactNode, useEffect, useRef, useState } from 'react';

export interface ICarouselProps {
    autoScroll?: boolean;
    delay?: number;
    images?: string[];
    renderArrows?: boolean;
    renderIndicator?: boolean;
    theme: 'light' | 'dark';
}

export const Carousel: React.FC<ICarouselProps> = ({ autoScroll = false, delay = 3000, images, renderArrows = false, renderIndicator = false, theme }: ICarouselProps) => {
    const timeoutHandle = useRef(-1);
    const isHover = useRef(false);

    const [slideIndex, setSlideIndex] = useState(0);

    useEffect(() => {
        if (autoScroll) {
            timeoutHandle.current = window.setInterval(() => {
                if (!isHover.current) {
                    setSlideIndex((index) => (index >= (images ? images.length : 1) - 1 ? 0 : index + 1));
                }
            }, delay);
        }

        return () => {
            if (timeoutHandle.current) window.clearInterval(timeoutHandle.current);
        };
    }, [autoScroll]);

    return (
        <div className={`carousel ${theme}`} onMouseOver={() => (isHover.current = true)} onMouseLeave={() => (isHover.current = false)}>
            {renderArrows ? <button className='arrow previous' disabled={!images || slideIndex == 0} onClick={() => setSlideIndex((index) => index - 1)}></button> : null}
            <div className='slider-wrapper'>
                <div className='slider' style={{ transform: `translate3d(${-slideIndex * 100}%, 0, 0)` }}>
                    {images && images.length > 0 ? (
                        images.map<ReactNode>((url, index) => (
                            <div key={`slide-${index}`} className='slide'>
                                <img src={url} />
                            </div>
                        ))
                    ) : (
                        <div className='slide'>
                            <img src='/images/placeholder.svg' />
                        </div>
                    )}
                </div>
            </div>
            {renderArrows ? <button className='arrow next' disabled={!images || slideIndex == images.length - 1} onClick={() => setSlideIndex((index) => index + 1)}></button> : null}
            {renderIndicator && images && images.length > 1 ? (
                <ul className='indicator'>
                    {images.map<ReactNode>((_, index) => (
                        <li key={`indicator-${index}`} className={slideIndex == index ? 'current' : ''} data-index={index} onClick={(e) => setSlideIndex(parseInt(e.currentTarget.getAttribute('data-index') || '0'))}></li>
                    ))}
                </ul>
            ) : null}
        </div>
    );
};
