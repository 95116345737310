import React, { ReactNode } from 'react';

export interface IContributionsListProps {
    contributions?: string[];
}

export const ContributionsList: React.FC<IContributionsListProps> = ({ contributions }: IContributionsListProps) =>
    contributions && contributions.length > 0 ? (
        <ul className='contributions-list'>
            {contributions.map<ReactNode>((contribution, index) => (
                <li key={`contribution-${index}`}>{contribution}</li>
            ))}
        </ul>
    ) : null;
