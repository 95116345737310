import React from 'react';

export const AvaloniaIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={35} height={35} fill='none' {...props}>
        <path fill='#fff' d='M30.466 34.928h.21a4.256 4.256 0 0 0 4.23-3.753l.03-14.18C34.687 7.567 26.966 0 17.478 0 7.833 0 .014 7.819.014 17.464c0 9.542 7.652 17.3 17.155 17.464z' />
        <path
            fill='#8B44AC'
            fillRule='evenodd'
            d='M17.524 5.948c-5.497 0-10.094 3.853-11.238 9.006a2.603 2.603 0 0 1 0 5.01c1.144 5.154 5.74 9.007 11.238 9.007 2.001 0 3.883-.51 5.522-1.409v1.33h5.99V17.937c.005-.145 0-.33 0-.477 0-6.357-5.155-11.511-11.512-11.511zM12.01 17.459a5.514 5.514 0 1 1 11.028 0 5.514 5.514 0 0 1-11.028 0'
            clipRule='evenodd'
        />
        <path fill='#8B44AC' d='M7.368 17.452a1.842 1.842 0 1 1-3.684 0 1.842 1.842 0 0 1 3.684 0' />
    </svg>
);
