import { faGithub, faNpm, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faDownload, faExternalLinkAlt, faLink, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties } from 'react';
import { useMemo } from 'react';

import { Link, Type } from '#/types';
import { getTextWidth } from '#/utils';

import { TextIcon } from '../TextIcon';

export interface LinkItemProps {
    link: Link;
}

export const LinkItem: React.FC<LinkItemProps> = ({ link }: LinkItemProps) => {
    const textWidth = useMemo(() => getTextWidth(link.name || ''), [link.name]);

    let icon: IconDefinition;

    switch (link.type) {
        case Type.Download:
            icon = faDownload;
            break;
        case Type.Github:
            icon = faGithub;
            break;
        case Type.Npm:
            icon = faNpm;
            break;
        case Type.Site:
            icon = faExternalLinkAlt;
            break;
        case Type.Youtube:
            icon = faYoutube;
            break;
        default:
            icon = faLink;
    }

    return (
        <a target='_blank' rel='noopener noreferrer' className={`link-item ${link.type} ${link.name ? '' : 'icon-only'}`} href={link.url || '#'} style={{ '--text-width': `${textWidth}px` } as CSSProperties}>
            {link.type == Type.Xda ? <TextIcon fill='#ffffff' fontFamily='Continuum Medium, sans-serif' fontSize={8} text='xda' textColor='var(--link-color)' /> : <FontAwesomeIcon icon={icon} />}
            {link.name ? <span className='name'>{link.name}</span> : null}
        </a>
    );
};
