import { faFacebook, faGithub, faGoogle, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import { XdaLogo } from '#/images';

export interface Config {
    FullName: string;
    Name: string;
    IAM: string[];
    BrandName: string;
    BrandDescription: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Social: { Icon: any; Url: string }[];
}

const config: Config = {
    FullName: 'Dineshkumar T',
    Name: 'Devil7DK',
    IAM: ['.NET Developer', 'Android Developer', 'Custom ROM Developer', 'Custom Recovery Developer', 'Full Stack Web Developer'],
    BrandName: 'Devil7 Softwares',
    BrandDescription: 'The playground of a self-taught developer!',
    Social: [
        { Icon: faGithub, Url: 'https://github.com/Devil7DK' },
        { Icon: faGoogle, Url: 'mailto:dineshthangavel47@gmail.com' },
        { Icon: faFacebook, Url: 'https://www.facebook.com/dineshthangavel47/' },
        { Icon: faLinkedin, Url: 'https://www.linkedin.com/in/devil7dk/' },
        { Icon: XdaLogo, Url: 'https://forum.xda-developers.com/m/devil7dk.6958131' },
    ],
};

export default config;
