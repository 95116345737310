import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { NotificationManager } from 'react-notifications';

import { ContactQueriesEndpoint } from '~/EndPoints';
import { IContactQuery } from '~/interfaces';

export class Contact {
    public loading: boolean;
    public error?: string;

    constructor() {
        this.loading = false;

        makeAutoObservable(this);
    }

    public setLoading(value: boolean): void {
        this.loading = value;
    }

    public async submitQuery(value: IContactQuery): Promise<boolean> {
        try {
            this.setLoading(true);

            await axios.post(ContactQueriesEndpoint, value);

            NotificationManager.info('Successfully submitted contact query!', 'Success', 5000);

            return true;
        } catch (error: any) {
            NotificationManager.info('Failed to send mail!', 'Success', 5000);
            this.error = `Failed to send mail! ${error.message || error}`;
        } finally {
            this.setLoading(false);
        }

        return false;
    }
}
