import React from 'react';

export const CMakeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={48} height={48} {...props}>
        <defs>
            <linearGradient xlinkHref='#lang-cmake_svg__a' id='lang-cmake_svg__f' x1={24} x2={4} y1={4} y2={44} gradientUnits='userSpaceOnUse' />
            <linearGradient xlinkHref='#lang-cmake_svg__b' id='lang-cmake_svg__e' x1={44} x2={24} y1={44} y2={4} gradientUnits='userSpaceOnUse' />
            <linearGradient xlinkHref='#lang-cmake_svg__c' id='lang-cmake_svg__g' x1={44} x2={4} gradientUnits='userSpaceOnUse' />
            <linearGradient id='lang-cmake_svg__a'>
                <stop
                    style={{
                        stopColor: '#197cf1',
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopColor: '#20bcfa',
                    }}
                />
            </linearGradient>
            <linearGradient id='lang-cmake_svg__d'>
                <stop
                    style={{
                        stopColor: '#292c2f',
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopOpacity: 0,
                    }}
                />
            </linearGradient>
            <linearGradient id='lang-cmake_svg__b'>
                <stop
                    style={{
                        stopColor: '#c61423',
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopColor: '#dc2b41',
                    }}
                />
            </linearGradient>
            <linearGradient id='lang-cmake_svg__c'>
                <stop
                    style={{
                        stopColor: '#42a603',
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopColor: '#78da06',
                    }}
                />
            </linearGradient>
            <linearGradient xlinkHref='#lang-cmake_svg__d' id='lang-cmake_svg__h' x1={400.571} x2={416.572} y1={522.798} y2={538.798} gradientUnits='userSpaceOnUse' />
        </defs>
        <path
            d='m24 4 2.938 33.297L44 44z'
            style={{
                fill: 'url(#lang-cmake_svg__e)',
                fillRule: 'evenodd',
            }}
        />
        <path
            d='M24 4 4 44l21.795-19.668z'
            style={{
                fill: 'url(#lang-cmake_svg__f)',
                fillRule: 'evenodd',
            }}
        />
        <path
            d='M16.133 33.053 4.003 43.996 44 44zM4.035 43.93l-.072.066h.039z'
            style={{
                fill: 'url(#lang-cmake_svg__g)',
                fillRule: 'evenodd',
            }}
        />
        <path
            d='m410.571 523.798-10 9 11 11h17v-4z'
            style={{
                fill: 'url(#lang-cmake_svg__h)',
                opacity: 0.2,
                fillRule: 'evenodd',
            }}
            transform='translate(-384.571 -499.798)'
        />
    </svg>
);
