import './styles.scss';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faAddressCard, faBars, faChild, faCode, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { DeveloperLogo } from '#/images';

interface BaseNavItem {
    icon: IconDefinition;
    label: string;
}
interface NavItem extends BaseNavItem {
    path: string;
}
interface ExternalNavItem extends BaseNavItem {
    href: string;
}

const navItems: NavItem[] = [
    { icon: faHome, label: 'Home', path: '/' },
    { icon: faCode, label: 'Projects', path: '/projects' },
    { icon: faEnvelope, label: 'Contact', path: '/contact' },
];

if (Date.now() > new Date(2025, 3, 1).getTime()) {
    navItems.push({ icon: faAddressCard, label: 'Resume', path: '/resume' });
}

const externalNavItems: ExternalNavItem[] = [{ icon: faChild, label: 'Tamil Baby Names', href: 'https://tamil-baby-names.devil7softwares.com/' }];

export const TopNavigationBar: React.FC = () => {
    const location = useLocation();

    const [expanded, setExpanded] = useState<boolean>(false);

    const handleExpandClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        setExpanded(!expanded);
        return false;
    };

    const handleLinkClick = () => {
        setExpanded(false);
    };

    return (
        <nav className={`top-nav-bar ${expanded && 'expanded'}`}>
            <div className='nav-overlay' onClick={handleExpandClick}></div>
            <Link className='nav-item brand' to='/' onClick={handleLinkClick}>
                <img src={DeveloperLogo} />
                <span title='Dineshkumar T'>Devil7DK</span>
            </Link>
            <div className='spacer'></div>
            <ul>
                {navItems.map<ReactNode>((navItem, index) => (
                    <li key={`nav-item-${index}`}>
                        <Link className={`nav-item ${location.pathname == navItem.path ? 'active' : ''}`} to={navItem.path} onClick={handleLinkClick}>
                            <FontAwesomeIcon icon={navItem.icon} />
                            <span>{navItem.label}</span>
                        </Link>
                    </li>
                ))}
                {!!externalNavItems.length && <li className='splitter'></li>}
                {externalNavItems.map<ReactNode>((navItem, index) => (
                    <li key={`external-nav-item-${index}`}>
                        <a className='nav-item' href={navItem.href}>
                            <FontAwesomeIcon icon={navItem.icon} />
                            <span>{navItem.label}</span>
                        </a>
                    </li>
                ))}
            </ul>
            <Link className='nav-item expand' to='#' onClick={handleExpandClick}>
                <FontAwesomeIcon icon={faBars} />
            </Link>
        </nav>
    );
};
