import React from 'react';

import { IconMap, SVGIconInfo, TextIconInfo } from '#/types';

import { TextIcon } from '../TextIcon';

export interface IIconListItemProps {
    iconMap: IconMap;
    value: string;
}

// TODO: Implement popup for mobile devices to show details about language
export const IconListItem: React.FC<IIconListItemProps> = ({ iconMap, value }: IIconListItemProps) => {
    const info = iconMap[value];

    const ItemIcon = (info as SVGIconInfo).icon != undefined ? (info as SVGIconInfo).icon : () => <TextIcon {...(info as TextIconInfo)} />;

    return (
        <div className='icon-list-item' title={info.title}>
            <ItemIcon style={(info as SVGIconInfo).icon != undefined && info.fill ? { background: info.fill } : undefined} />
            <div className='name'>{info.title}</div>
        </div>
    );
};
