import React from 'react';

export const DefaultAvatar: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns='http://www.w3.org/2000/svg' className='default-avatar_svg__icon' viewBox='0 0 1024 1024' {...props}>
        <path
            fill='#666'
            d='M512 24.381C242.59 24.381 24.381 242.591 24.381 512S242.591 999.619 512 999.619 999.619 781.409 999.619 512 781.409 24.381 512 24.381zm-7.314 170.667c90.21 0 164.571 70.704 164.571 158.476S596.114 512 504.686 512c-90.21 0-163.353-70.705-163.353-158.476s73.143-158.476 163.353-158.476m287.695 587.58c0 45.105-95.086 46.324-212.114 46.324H443.733c-117.028 0-212.114 0-212.114-46.323v-13.41c0-113.371 95.086-204.8 212.114-204.8h136.534c117.028 0 212.114 91.429 212.114 204.8v13.41z'
        />
    </svg>
);
