import React from 'react';

export const SQLiteIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' xmlSpace='preserve' viewBox='0 0 256 256' {...props}>
        <defs>
            <linearGradient id='database-sqlite_svg__a' x1={0} x2={1} y1={0} y2={0} gradientTransform='matrix(0 -91.8907 -91.8907 0 85.88 161.434)' gradientUnits='userSpaceOnUse' spreadMethod='pad'>
                <stop
                    offset={0}
                    style={{
                        stopOpacity: 1,
                        stopColor: '#97d9f6',
                    }}
                />
                <stop
                    offset={0.92}
                    style={{
                        stopOpacity: 1,
                        stopColor: '#0f80cc',
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopOpacity: 1,
                        stopColor: '#0f80cc',
                    }}
                />
            </linearGradient>
            <linearGradient
                xlinkHref='#database-sqlite_svg__a'
                id='database-sqlite_svg__b'
                x1={-15.615}
                x2={-6.741}
                y1={-9.108}
                y2={-9.108}
                gradientTransform='matrix(0 -9.18907 -9.18907 0 8.588 16.143)'
                gradientUnits='userSpaceOnUse'
                spreadMethod='pad'
            />
        </defs>
        <path
            d='M103.096 71.596c-.057.722-.091 1.191-.091 1.191s-2.189 14.758-4.795 19.162c-.412.698.045 3.565 1.196 7.812.672-1.163 3.511-6.137 4.081-7.74.642-1.812.777-2.331.777-2.331s-1.557 8.011-4.112 12.686c.56 1.89 1.229 3.979 1.986 6.212.968-1.698 3.285-5.809 3.795-7.235.103-.293.19-.542.268-.77l.075.411c-.585 2.482-1.734 6.801-3.307 9.992 3.49 18.165 15.393 42.445 27.596 53.278H50.96c-5.335 0-9.7-4.366-9.7-9.701v-87.79c0-5.334 4.365-9.7 9.7-9.7h52.43c-.378 4.577-.504 9.64-.294 14.523'
            style={{
                fill: '#0f80cc',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
            }}
            transform='matrix(1.93905 0 0 -1.93905 -61.982 334.432)'
        />
        <path
            d='M99.406 99.76c.672-1.162 3.511-6.136 4.081-7.739.642-1.812.777-2.331.777-2.331s-1.557 8.011-4.112 12.686c.56 1.89 1.229 3.979 1.986 6.212.885-1.553 2.896-5.12 3.623-6.81l.082.954c-.644 2.475-1.622 5.715-2.874 8.254 3.214 16.725 13.559 38.625 24.704 50.448H50.96a6.879 6.879 0 0 1-6.87-6.871V73.179c17.373 6.668 38.322 12.763 56.352 12.502-.67 2.581-1.431 4.915-2.232 6.268-.412.698.045 3.565 1.196 7.812'
            style={{
                fill: 'url(#database-sqlite_svg__b)',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
            }}
            transform='matrix(1.93905 0 0 -1.93905 -61.982 334.432)'
        />
        <path
            d='M149.133 167.137c-5.452 4.862-12.053 2.909-18.568-2.873a46.045 46.045 0 0 1-2.892-2.83c-11.145-11.823-21.49-33.723-24.704-50.448 1.252-2.539 2.23-5.779 2.874-8.254.165-.635.314-1.231.433-1.738.283-1.2.435-1.978.435-1.978s-.1.378-.51 1.567c-.078.228-.165.477-.268.77a8.925 8.925 0 0 1-.172.425c-.727 1.69-2.738 5.257-3.623 6.81a170.097 170.097 0 0 1-1.986-6.212c2.555-4.675 4.112-12.686 4.112-12.686s-.135.519-.777 2.331c-.57 1.603-3.409 6.577-4.082 7.74-1.15-4.247-1.607-7.114-1.195-7.812.8-1.353 1.563-3.687 2.232-6.268 1.512-5.815 2.563-12.894 2.563-12.894s.034-.469.091-1.19a118.47 118.47 0 0 1 .294-14.523c.501-6.058 1.444-11.262 2.646-14.047l.816.445c-1.765 5.487-2.482 12.678-2.168 20.971.475 12.676 3.392 27.963 8.782 43.896 9.106 24.052 21.74 43.35 33.303 52.566-10.539-9.518-24.803-40.327-29.073-51.736-4.781-12.776-8.169-24.765-10.211-36.252 3.523 10.769 14.914 15.398 14.914 15.398s5.587 6.89 12.116 16.734c-3.911-.892-10.333-2.419-12.484-3.323-3.173-1.331-4.028-1.785-4.028-1.785s10.278 6.259 19.096 9.093c12.127 19.1 25.339 46.234 12.034 58.103'
            style={{
                fill: '#003b57',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
            }}
            transform='matrix(1.93905 0 0 -1.93905 -61.982 334.432)'
        />
    </svg>
);
