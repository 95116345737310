import React from 'react';

export const SemanticUIIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' viewBox='0 0 128 128' {...props}>
        <path
            fill='#37BDB2'
            d='m19.826 101.302 13.128-15.601c10.04 8.649 21.469 13.9 34.907 13.9 11.429 0 17.299-5.251 17.299-11.894 0-7.567-6.179-9.885-22.396-13.592-22.395-5.094-38.304-11.428-38.304-31.97 0-19.615 16.063-33.053 39.231-33.053 17.298 0 30.736 5.406 41.548 14.673L93.346 40.137c-9.422-7.568-20.079-11.738-30.272-11.738-9.73 0-15.6 5.097-15.6 11.43 0 7.722 6.332 10.193 22.703 13.9 22.86 5.097 37.997 11.893 37.997 31.509 0 20.233-15.29 33.671-41.24 33.671-18.379-.001-34.752-6.179-47.108-17.607z'
        />
    </svg>
);
