import axios from 'axios';
import { makeAutoObservable } from 'mobx';

import { Project } from '#/types';

export class Projects {
    public loading: boolean;
    public projects: Project[];
    public error?: string;

    constructor() {
        this.loading = false;
        this.projects = [];

        makeAutoObservable(this);
    }

    private setError(value?: string): void {
        this.error = value;
    }

    private setLoading(value: boolean): void {
        this.loading = value;
    }

    private setProjects(value: Project[]): void {
        this.projects = value;
    }

    public load(): void {
        this.setLoading(true);
        this.setError(undefined);

        axios
            .get<Project[]>('/projects.json', { baseURL: '' })
            .then((response) => {
                const projects = response.data
                    .sort((a, b) => ((a.description ? a.description.length : 0) > (b.description ? b.description.length : 0) ? -1 : (a.description ? a.description.length : 0) < (b.description ? b.description.length : 0) ? 1 : 0))
                    .sort((a, b) => ((a.images ? a.images.length : 0) > (b.images ? b.images.length : 0) ? -1 : (a.images ? a.images.length : 0) < (b.images ? b.images.length : 0) ? 1 : 0))
                    .sort((a, b) => (a.starred && !b.starred ? -1 : !a.starred && b.starred ? 1 : 0));

                this.setProjects(projects);
            })
            .catch((error) => {
                this.setError(error.message || error);
            })
            .finally(() => {
                this.setLoading(false);
            });
    }
}
