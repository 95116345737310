import React from 'react';

export const KotlinIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox='0 0 128 128' {...props}>
        <linearGradient id='lang-kotlin_svg__a' x1={-11.899} x2={40.299} y1={48.694} y2={-8.322} gradientUnits='userSpaceOnUse'>
            <stop offset={0} stopColor='#1c93c1' />
            <stop offset={0.163} stopColor='#2391c0' />
            <stop offset={0.404} stopColor='#378bbe' />
            <stop offset={0.696} stopColor='#587eb9' />
            <stop offset={0.995} stopColor='#7f6cb1' />
        </linearGradient>
        <path fill='url(#lang-kotlin_svg__a)' d='M0 0h65.4L0 64.4z' />
        <linearGradient id='lang-kotlin_svg__b' x1={43.553} x2={95.988} y1={149.174} y2={94.876} gradientUnits='userSpaceOnUse'>
            <stop offset={0} stopColor='#1c93c1' />
            <stop offset={0.216} stopColor='#2d8ebf' />
            <stop offset={0.64} stopColor='#587eb9' />
            <stop offset={0.995} stopColor='#7f6cb1' />
        </linearGradient>
        <path fill='url(#lang-kotlin_svg__b)' d='M128 128 64.6 62.6 0 128z' />
        <linearGradient id='lang-kotlin_svg__c' x1={3.24} x2={92.481} y1={95.249} y2={2.116} gradientUnits='userSpaceOnUse'>
            <stop offset={0} stopColor='#c757a7' />
            <stop offset={0.046} stopColor='#ca5a9e' />
            <stop offset={0.241} stopColor='#d66779' />
            <stop offset={0.428} stopColor='#e17357' />
            <stop offset={0.6} stopColor='#e97c3a' />
            <stop offset={0.756} stopColor='#ef8324' />
            <stop offset={0.888} stopColor='#f28817' />
            <stop offset={0.982} stopColor='#f48912' />
        </linearGradient>
        <path fill='url(#lang-kotlin_svg__c)' d='M0 128 128 0H64.6L0 63.7z' />
    </svg>
);
