import React, { SVGAttributes } from 'react';

export type IconInfo = {
    title: string;
} & (SVGIconInfo | TextIconInfo);

export type SVGIconInfo = {
    fill?: string;
    icon: React.FunctionComponent<SVGAttributes<SVGElement>>;
};

export type TextIconInfo = {
    fill: string;
    fontFamily: string;
    fontSize: number;
    text: string;
    textColor: string;
};
