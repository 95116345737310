import { observer } from 'mobx-react';
import React from 'react';
import { CSSProperties } from 'react';

import { DefaultAvatar } from '#/images';
import { useStore } from '#/store';

export interface IPersonProps {
    index: number;
    userName: string;
}

export interface IPersonCSS extends CSSProperties {
    '--index': number;
}

export const Person: React.FC<IPersonProps> = observer(({ index, userName }: IPersonProps) => {
    const { githubUsers } = useStore();

    const user = githubUsers.get(userName);

    return (
        <div className='person' title={user && user.name && user.name != userName ? `${user.name} (${userName})` : userName} style={{ '--index': index } as IPersonCSS}>
            {user && user.avatar_url ? <img src={user.avatar_url} /> : <DefaultAvatar />}
            {user ? <div className='name'>{user.name || userName}</div> : null}
        </div>
    );
});
