import './styles.scss';

import React from 'react';
import { useState } from 'react';
import Markdown from 'markdown-to-jsx';

import { StarIcon } from '#/images';
import { Project, Variant } from '#/types';
import { DatabaseIconMap, FrameworkIconMap, LanguageIconMap, PlatformIconMap } from '#/utils';

import { IconList } from '../IconList';
import { LinksList } from '../LinksList';
import { PeopleList } from '../PeopleList';
import { ProjectInfoModal } from '../ProjectInfoModal';

export interface IProjectCardProps {
    project: Project;
}

export const ProjectCard: React.FC<IProjectCardProps> = ({ project }: IProjectCardProps) => {
    const [infoOpen, setInfoOpen] = useState<boolean>(false);

    return (
        <>
            <div
                className='project-card'
                onClick={() => {
                    setInfoOpen(true);
                }}
            >
                {project.starred ? (
                    <div className='badge'>
                        <i className='left'></i>
                        <i className='right'></i>
                        <StarIcon />
                    </div>
                ) : null}
                <div className='header'>
                    <span className='name' title={project.name}>
                        {project.name}
                    </span>
                    <PeopleList people={project.people} variant={Variant.Minimal} />
                </div>
                <div className='content'>
                    <div className='thumbnail-container'>
                        <div className='img-wrapper'>
                            <img className='thumbnail' src={project.images && project.images.length > 0 ? project.images[0] : '/images/placeholder.svg'} />
                            {project.images && project.images.length > 1 ? <span className='image-count'>+{project.images.length - 1}</span> : null}
                        </div>
                    </div>
                    <Markdown className='description'>{project.description || 'No description provided'}</Markdown>
                    <div className='spacer'></div>
                    <IconList caption='Languages' iconMap={LanguageIconMap} items={project.languages} variant={Variant.Minimal} />
                    <IconList caption='Frameworks' iconMap={FrameworkIconMap} items={project.frameworks} variant={Variant.Minimal} />
                    <IconList caption='Database' iconMap={DatabaseIconMap} items={project.database} variant={Variant.Minimal} />
                    <IconList caption='Platforms' iconMap={PlatformIconMap} items={project.platforms} variant={Variant.Minimal} />
                </div>
                <LinksList links={project.links} variant={Variant.Minimal} />
            </div>
            <ProjectInfoModal isOpen={infoOpen} project={project} onClose={() => setInfoOpen(false)} />
        </>
    );
};

export * from './ProjectCardSkeleton';
