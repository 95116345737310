export * from "./BatchScriptIcon";
export * from "./CLanguageIcon";
export * from "./CMakeIcon";
export * from "./CPPIcon";
export * from "./CSharpIcon";
export * from "./CSSIcon";
export * from "./HTMLIcon";
export * from "./JavaIcon";
export * from "./JavaScriptIcon";
export * from "./KotlinIcon";
export * from "./MakeFileIcon";
export * from "./SCSSIcon";
export * from "./ShellScriptIcon";
export * from "./SoongFileIcon";
export * from "./TypescriptIcon";
export * from "./VisualBasicIcon";
export * from "./XAMLIcon";
