import {
    AndroidIcon,
    ArduinoIcon,
    AvaloniaIcon,
    BatchScriptIcon,
    CLanguageIcon,
    CMakeIcon,
    CPPIcon,
    CSharpIcon,
    CSSIcon,
    DotNetCoreIcon,
    DotNetIcon,
    HTMLIcon,
    IOSIcon,
    JavaIcon,
    JavaScriptIcon,
    KotlinIcon,
    LinuxIcon,
    MacIcon,
    MakeFileIcon,
    MongoDBIcon,
    MSAccessIcon,
    MSSQLIcon,
    MySQLIcon,
    NodeIcon,
    PostgreSQLIcon,
    ReactIcon,
    SCSSIcon,
    SemanticUIIcon,
    ShellScriptIcon,
    SoongFileIcon,
    SQLiteIcon,
    TypescriptIcon,
    VisualBasicIcon,
    WebIcon,
    WindowsIcon,
    XAMLIcon,
} from '#/images';
import { ContributorIcon, DeveloperIcon, FounderIcon, MaintainerIcon } from '#/images/roles';
import { Database, Framework, IconInfo, IconMap, Language, Platform, Role } from '#/types';

type DatabaseIconMap = IconMap & Record<Database, IconInfo>;
type FrameworkIconMap = IconMap & Record<Framework, IconInfo>;
type LanguageIconMap = IconMap & Record<Language, IconInfo>;
type PlatformIconMap = IconMap & Record<Platform, IconInfo>;
type RoleIconMap = IconMap & Record<Role, IconInfo>;

export const DatabaseIconMap: DatabaseIconMap = {
    mongo: {
        icon: MongoDBIcon,
        title: 'MongoDB',
    },
    msaccess: {
        icon: MSAccessIcon,
        title: 'Microsoft Access',
    },
    mssql: {
        icon: MSSQLIcon,
        title: 'Microsoft SQL',
    },
    mysql: {
        icon: MySQLIcon,
        title: 'MySQL',
    },
    postgres: {
        icon: PostgreSQLIcon,
        title: 'PostgreSQL',
    },
    sqlite: {
        icon: SQLiteIcon,
        title: 'SQLite',
    },
};

export const FrameworkIconMap: FrameworkIconMap = {
    avalonia: {
        icon: AvaloniaIcon,
        title: 'AvaloniaUI',
    },
    'dotnet-core': {
        icon: DotNetCoreIcon,
        title: '.NET Core/.NET',
    },
    dotnet: {
        fill: '#ffffff',
        icon: DotNetIcon,
        title: '.NET Framework',
    },
    nodejs: {
        icon: NodeIcon,
        title: 'NodeJS',
    },
    react: {
        icon: ReactIcon,
        title: 'React',
    },
    'semantic-ui': {
        icon: SemanticUIIcon,
        title: 'Semantic-UI',
    },
};

export const LanguageIconMap: LanguageIconMap = {
    asp: {
        fill: '#6d409d',
        title: 'ASP.NET',
        fontFamily: 'SegoeUI',
        fontSize: 16,
        textColor: '#fff',
        text: 'ASP',
    },
    batch: {
        icon: BatchScriptIcon,
        title: 'Batch Script',
    },
    c: {
        icon: CLanguageIcon,
        title: 'C Language',
    },
    cmake: {
        icon: CMakeIcon,
        title: 'CMake',
    },
    cpp: {
        icon: CPPIcon,
        title: 'C++ Language',
    },
    csharp: {
        icon: CSharpIcon,
        title: 'C# .NET',
    },
    css: {
        icon: CSSIcon,
        title: 'CSS',
    },
    html: {
        icon: HTMLIcon,
        title: 'HTML',
    },
    java: {
        icon: JavaIcon,
        title: 'Java',
    },
    javascript: {
        icon: JavaScriptIcon,
        title: 'JavaScript',
    },
    kotlin: {
        icon: KotlinIcon,
        title: 'Kotlin',
    },
    make: {
        fill: '#ffffff',
        icon: MakeFileIcon,
        title: 'GNU Makefile',
    },
    scss: {
        icon: SCSSIcon,
        title: 'SASS/SCSS',
    },
    sh: {
        fill: '#ffffff',
        icon: ShellScriptIcon,
        title: 'Shell/Bash Script',
    },
    soong: {
        icon: SoongFileIcon,
        title: 'Soong File',
    },
    typescript: {
        icon: TypescriptIcon,
        title: 'TypeScript',
    },
    vb: {
        icon: VisualBasicIcon,
        title: 'Visual Basic .NET',
    },
    xaml: {
        icon: XAMLIcon,
        title: 'WPF/XAML',
    },
};

export const PlatformIconMap: PlatformIconMap = {
    android: {
        icon: AndroidIcon,
        title: 'Android',
    },
    arduino: {
        icon: ArduinoIcon,
        title: 'Arduino',
    },
    ios: {
        icon: IOSIcon,
        title: 'iOS',
    },
    linux: {
        icon: LinuxIcon,
        title: 'Linux/GNU',
    },
    mac: {
        icon: MacIcon,
        title: 'MacOS',
    },
    web: {
        icon: WebIcon,
        title: 'Web',
    },
    windows: {
        icon: WindowsIcon,
        title: 'Windows',
    },
};

export const RoleIconMap: RoleIconMap = {
    contributer: {
        icon: ContributorIcon,
        title: 'Contributer',
    },
    developer: {
        icon: DeveloperIcon,
        title: 'Developer',
    },
    founder: {
        icon: FounderIcon,
        title: 'Founder',
    },
    maintainer: {
        icon: MaintainerIcon,
        title: 'Maintainer',
    },
};
