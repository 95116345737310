import './styles.scss';

import React, { ReactNode } from 'react';

import { IconMap, Variant } from '#/types';

import { IconListItem } from './IconListItem';

type IIconListBaseProps = {
    caption?: string;
    iconMap: IconMap;
    items?: string[];
};

export type IIconListProps =
    | (IIconListBaseProps & {
          caption: string;
          variant: Variant.Minimal;
      })
    | (IIconListBaseProps & {
          variant: Variant.Full;
      });

export const IconList: React.FC<IIconListProps> = ({ caption, iconMap, items, variant }: IIconListProps) =>
    items && items.length ? (
        <div className={`icon-list ${variant}`}>
            <span className='caption'>{caption}</span>
            <span>:</span>
            {items.map<ReactNode>((value, index) => (
                <IconListItem key={`icon-list-item-${index}`} iconMap={iconMap} value={value} />
            ))}
        </div>
    ) : null;
