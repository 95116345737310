const canvas = document.createElement('canvas');

export function getTextWidth(text: string, font = getCanvasFontSize()): number {
    const context = canvas.getContext('2d');
    if (context) {
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }
    return 0;
}

function getCssStyle(element: Element, prop: string) {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFontSize(element: Element = document.body) {
    const fontWeight = getCssStyle(element, 'font-weight') || 'normal';
    const fontSize = getCssStyle(element, 'font-size') || '16px';
    const fontFamily = getCssStyle(element, 'font-family') || 'Times New Roman';

    return `${fontWeight} ${fontSize} ${fontFamily}`;
}
