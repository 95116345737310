import './styles.scss';

import React from 'react';

import { TextIconInfo } from '#/types';

export const TextIcon: React.FC<TextIconInfo> = ({ fontFamily, fontSize, fill, text, textColor }: TextIconInfo) => {
    return (
        <div className='text-icon' style={{ background: fill }}>
            <div className='text' style={{ fontFamily: fontFamily, fontSize: fontSize, color: textColor }}>
                {text}
            </div>
        </div>
    );
};
