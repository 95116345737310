import './styles.scss';

import React from 'react';

export interface IProgressOverlayProps {
    loading?: boolean;
}

export const ProgressOverlay: React.FC<IProgressOverlayProps> = ({ loading = true }: IProgressOverlayProps) =>
    loading ? (
        <div className='progress-overlay'>
            <div className='loader'>
                <div></div>
                <div></div>
            </div>
        </div>
    ) : null;
