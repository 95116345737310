/**
 * Auto generated from projects.schema.json using https://app.quicktype.io/?l=ts
 */

/**
 * Object describing a single project
 */
export interface Project {
    /**
     * Notable contributions
     */
    contributions?: string[];
    database?: Database[];
    /**
     * Markdown description of project
     */
    description?: string;
    frameworks?: Framework[];
    images?: string[];
    languages?: Language[];
    links?: Link[];
    /**
     * Name of the project
     */
    name: string;
    /**
     * GitHub usernames of involved people
     */
    people?: string[];
    platforms?: Platform[];
    roles?: Role[];
    starred?: boolean;
    status?: Status;
    tags?: string[];
    year?: string;
}

export enum Database {
    Mongo = 'mongo',
    Msaccess = 'msaccess',
    Mssql = 'mssql',
    Mysql = 'mysql',
    Postgres = 'postgres',
    Sqlite = 'sqlite',
}

export enum Framework {
    Avalonia = 'avalonia',
    Dotnet = 'dotnet',
    DotnetCore = 'dotnet-core',
    Nodejs = 'nodejs',
    React = 'react',
    SemanticUI = 'semantic-ui',
}

export enum Language {
    ASP = 'asp',
    Batch = 'batch',
    C = 'c',
    CSS = 'css',
    Cmake = 'cmake',
    Cpp = 'cpp',
    Csharp = 'csharp',
    HTML = 'html',
    Java = 'java',
    Javascript = 'javascript',
    Kotlin = 'kotlin',
    Make = 'make',
    Scss = 'scss',
    Sh = 'sh',
    Soong = 'soong',
    Typescript = 'typescript',
    VB = 'vb',
    XAML = 'xaml',
}

/**
 * Describes links assosiated with a project
 */
export interface Link {
    name?: string;
    type?: Type;
    url?: string;
}

export enum Type {
    Download = 'download',
    Github = 'github',
    Npm = 'npm',
    Site = 'site',
    Xda = 'xda',
    Youtube = 'youtube',
}

export enum Platform {
    Android = 'android',
    Arduino = 'arduino',
    Ios = 'ios',
    Linux = 'linux',
    MAC = 'mac',
    Web = 'web',
    Windows = 'windows',
}

export enum Role {
    Contributer = 'contributer',
    Developer = 'developer',
    Founder = 'founder',
    Maintainer = 'maintainer',
}

export enum Status {
    Active = 'active',
    Completed = 'completed',
    Dead = 'dead',
    Dropped = 'dropped',
}
