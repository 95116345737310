import { createContext, useContext } from 'react';

import { Contact } from './Contact';
import { GithubUsers as GithubUsers } from './GithubUsers';
import { Projects } from './Projects';
import { Auth } from './Auth';

export class RootStore {
    public auth: Auth;
    public contact: Contact;
    public githubUsers: GithubUsers;
    public projects: Projects;

    constructor() {
        this.auth = new Auth();
        this.contact = new Contact();
        this.githubUsers = new GithubUsers();
        this.projects = new Projects();
    }
}

const StoreContext = createContext<RootStore>(new RootStore());

export const StoreProvider = StoreContext.Provider;
export const useStore = (): RootStore => useContext(StoreContext);
