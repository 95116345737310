import './styles.scss';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import Typewriter from 'typewriter-effect';

import Config from '#/config';
import { DeveloperLogo } from '#/images';

export const Developer: React.FC = () => (
    <div className='dev-container'>
        <div className='dev-name-avatar'>
            <img className='dev-avatar' src={DeveloperLogo} />
            <div>
                <div className='dev-name'>{Config.FullName}</div>
                <div className='flex'>
                    <Typewriter
                        onInit={() => {
                            return;
                        }}
                        options={{
                            strings: Config.IAM,
                            autoStart: true,
                            loop: true,
                        }}
                    />
                </div>
            </div>
        </div>
        <div className='dev-social'>
            {Config.Social.map<ReactNode>(({ Icon, Url }, index) => (
                <a key={`social_${index}`} href={Url} target='_blank' rel='noreferrer'>
                    {(Icon as IconDefinition)['icon'] == undefined ? <Icon /> : <FontAwesomeIcon icon={Icon as IconDefinition} />}
                </a>
            ))}
        </div>
    </div>
);
