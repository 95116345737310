import 'react-notifications/lib/notifications.css';

import axios from 'axios';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { NotificationContainer } from 'react-notifications';
import { BrowserRouter } from 'react-router-dom';

import { App } from '#/App';
import { RootStore, StoreProvider } from '#/store';
import { BaseEndpoint } from '~/EndPoints';
import { AnalyticsPageViewProvider } from './utils';

axios.defaults.baseURL = BaseEndpoint;

const rootStore = new RootStore();

createRoot(document.getElementById('root') || document.body).render(
    <StoreProvider value={rootStore}>
        <NotificationContainer />
        <BrowserRouter>
            <AnalyticsPageViewProvider>
                <App />
            </AnalyticsPageViewProvider>
        </BrowserRouter>
    </StoreProvider>
);
