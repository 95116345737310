import React from 'react';

export const MSAccessIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' viewBox='0 0 1993.481 1947.121' {...props}>
        <path fill='#881421' d='M463.6 1251.72v463.6c0 128 342.462 231.8 764.94 231.8s764.94-103.8 764.94-231.8v-463.6H463.6z' />
        <path fill='#AF2031' d='M463.6 741.76v509.96c0 128 342.462 231.8 764.94 231.8s764.94-103.8 764.94-231.8V741.76H463.6z' />
        <path fill='#C94F60' d='M463.6 231.8v509.96c0 128 342.462 231.8 764.94 231.8s764.94-103.8 764.94-231.8V231.8H463.6z' />
        <ellipse cx={1228.541} cy={231.8} fill='#E08095' rx={764.94} ry={231.8} />
        <path d='M1027.662 417.24H463.6v1159h564.062c46.869-.153 84.825-38.109 84.978-84.978V502.218c-.152-46.869-38.109-84.825-84.978-84.978z' opacity={0.1} />
        <path d='M981.302 463.6H463.6v1159h517.702c46.869-.153 84.825-38.109 84.978-84.978V548.578c-.152-46.869-38.109-84.825-84.978-84.978z' opacity={0.2} />
        <path d='M981.302 463.6H463.6v1066.28h517.702c46.869-.153 84.825-38.109 84.978-84.978V548.578c-.152-46.869-38.109-84.825-84.978-84.978z' opacity={0.2} />
        <path d='M934.943 463.6H463.6v1066.28h471.342c46.869-.153 84.825-38.109 84.978-84.978V548.578c-.152-46.869-38.109-84.825-84.977-84.978z' opacity={0.2} />
        <linearGradient id='database-msaccess_svg__a' x1={177.181} x2={842.74} y1={1551.92} y2={399.2} gradientTransform='matrix(1 0 0 -1 0 1949.12)' gradientUnits='userSpaceOnUse'>
            <stop
                offset={0}
                style={{
                    stopColor: '#b72c3f',
                }}
            />
            <stop
                offset={0.5}
                style={{
                    stopColor: '#b12334',
                }}
            />
            <stop
                offset={1}
                style={{
                    stopColor: '#9d1623',
                }}
            />
        </linearGradient>
        <path fill='url(#database-msaccess_svg__a)' d='M84.978 463.6h849.965c46.932 0 84.978 38.046 84.978 84.978v849.964c0 46.932-38.046 84.978-84.978 84.978H84.978C38.046 1483.52 0 1445.474 0 1398.542V548.578C0 501.646 38.046 463.6 84.978 463.6' />
        <path
            fill='#FFF'
            d='M448.904 697.301h125.172l201.11 552.472H656.968l-44.32-131.755H405.743l-43.532 131.755H244.735l204.169-552.472zm137.133 335.6L521.736 832.95a262.066 262.066 0 0 1-11.775-45.108h-2.318a255.23 255.23 0 0 1-11.544 46.36l-64.904 198.792z'
        />
    </svg>
);
