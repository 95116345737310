import './App.scss';

import DrifterStars from '@devil7softwares/react-drifter-stars';
import React, { useCallback } from 'react';

import { TopNavigationBar } from '#/components';
import { Pages } from '#/pages';

export const App: React.FC = () => {
    const transformCanvasSize = useCallback(() => ({ height: document.body.clientHeight, width: document.body.clientWidth }), []);

    return (
        <div className='gradiant-background'>
            <DrifterStars transformCanvasSize={transformCanvasSize} />
            <TopNavigationBar />
            <Pages />
        </div>
    );
};
