import axios from 'axios';
import { makeAutoObservable } from 'mobx';

import { GithubUser } from '../types';

export class GithubUsers {
    private users: Record<string, GithubUser>;

    constructor() {
        this.users = {};

        makeAutoObservable(this);
    }

    private set(user: GithubUser): void {
        this.users[user.login] = user;
    }

    public get(userName: string): GithubUser | undefined {
        if (!this.users[userName]) {
            this.users[userName] = {} as GithubUser;

            axios
                .get<GithubUser>(`https://api.github.com/users/${userName}`)
                .then((response) => {
                    this.set(response.data);
                })
                .catch((error) => console.error(`Failed to fetch user info of ${userName} from GitHub.`, error));
        }

        return this.users[userName];
    }
}
