import './styles.scss';

import React, { ReactNode } from 'react';

import { Link, Type, Variant } from '#/types';

import { LinkItem } from './LinkItem';

export interface LinksListProps {
    links: Link[] | undefined;
    variant: Variant;
}

export const LinksList: React.FC<LinksListProps> = ({ links, variant }: LinksListProps) => {
    return links && links.length > 0 ? (
        <div className={`links-list ${variant}`} onClick={(e) => e.stopPropagation()}>
            <div className='left'>
                {links.map<ReactNode>((link, index) => (link.type != Type.Github ? <LinkItem key={`link-${index}`} link={link} /> : null))}
            </div>
            <div className='right'>
                {links.map<ReactNode>((link, index) => (link.type == Type.Github ? <LinkItem key={`link-${index}`} link={link} /> : null))}
            </div>
        </div>
    ) : null;
};
